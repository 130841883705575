// Table colors
.linkBox {
  padding: 20px;
  background: #0073b7;
  margin-bottom: 20px;

  h3 {
    padding: 0;
    margin: 0;
    color: white;
  }
}

.isLate {
  background: rgba(255, 0, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 0, 0, 0.2) !important;
  }
}
.shouldAlert{
  background: rgba(255, 192, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 192, 0, 0.2) !important;
  }
}